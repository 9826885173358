export default [
    {
        prop: 'companyName',
        label: '公司名稱',
        width: '150',
        showOverflowTooltip: false,
        align: 'center',
        sortable: true
    },
    {
        prop: 'branchName',
        label: '分店名稱',
        width: '150',
        showOverflowTooltip: false,
        align: 'center',
        sortable: true
    },
    {
        prop: 'nickName',
        label: '暱稱',
        width: '150', //列寬
        showOverflowTooltip: false, //設置表格文字過長顯示省略號
        align: 'center',//對齊方式
        sortable: true
    },
    {
        prop: 'userName',
        label: '帳號',
        width: '150', //列寬
        showOverflowTooltip: false, //設置表格文字過長顯示省略號
        align: 'center',//對齊方式
        sortable: true
    },
    {
        prop: 'errorCount',
        label: '錯誤次數',
        width: '150', //列寬
        showOverflowTooltip: false, //設置表格文字過長顯示省略號
        align: 'center',//對齊方式
        sortable: true
    },
    {
        prop: 'isActiveString',
        label: '是否啟用',
        width: '150', //列寬
        showOverflowTooltip: false, //設置表格文字過長顯示省略號
        align: 'center',//對齊方式
        sortable: true
    },
    {
        prop: 'roleString',
        label: '角色類別',
        width: '150',
        showOverflowTooltip: false,
        align: 'center',
        sortable: true
    },
    {
        prop: 'stateString',
        label: '帳號狀態',
        width: '150',
        showOverflowTooltip: false,
        align: 'center',
        sortable: true
    },
    {
        prop: 'unlockDate',
        label: '解除鎖定時間',
        width: '200',
        showOverflowTooltip: false,
        align: 'center',
        sortable: true
    },
    {
        prop: 'mail',
        label: '信箱',
        width: '150',
        showOverflowTooltip: false,
        align: 'center',
        sortable: true
    },
    {
        prop: 'action',
        label: '操作',
        width: '250',
        showOverflowTooltip: true,
        align: 'center',
        render: 'action',
        sortable: false
    },
]
