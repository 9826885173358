<template>
    <div class="popDialog">
        <el-dialog
                v-if="addDialog"
                width="95%"
                top="30px"
                :visible.sync="addDialog"
                :before-close="closeDialog"
                :fullscreen="dialogFull"
                custom-class="custom-dialog">
            <template v-slot:title>
                <el-row>
                    <el-col :span="12" class="selectTitleMax">
                        <span>新增會員帳號資料</span>
                    </el-col>
                </el-row>
            </template>
            <el-row>
                <el-col :span="24">
                    <div class="containerDiv">
                        <el-row>
                            <el-col :span="4" class="selectTitleMid">
                                <span>帳號資料</span>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="3" class="selectTitle">
                                <span>公司<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-select v-model="companyId" placeholder=""
                                           @change="handleCompanyChange">
                                    <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                                    <el-option
                                            v-for="item in companyOptions"
                                            :key="item.companyId"
                                            :label="item.companyName"
                                            :value="item.companyId"
                                            :disabled="item.disabled">
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>分店<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-select v-model="branchId" placeholder="">
                                    <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                                    <el-option
                                            v-for="item in branchOptions"
                                            :key="item.branchId"
                                            :label="item.branchName"
                                            :value="item.branchId"
                                            :disabled="item.disabled">
                                    </el-option>
                                </el-select>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="3" class="selectTitle">
                                <span>帳號<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input
                                        v-model="userName"
                                        placeholder="請輸入帳號"

                                        @input="validateUsername"
                                ></el-input>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>密碼<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input
                                        :type="inputType"
                                        v-model="password"
                                        placeholder="請輸入密碼"
                                        style="width: calc(100% - 30px)"
                                        @input="validatePassword"></el-input>
                                <span
                                        class="eye-icon"
                                        @click="togglePasswordVisibility">
                                    {{ passwordVisible ? '👁️' : ' 👁️‍🗨️' }}</span>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>是否啟用<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-radio-group v-model="isActive">
                                    <el-radio label="1">啟用</el-radio>
                                    <el-radio label="0">不啟用</el-radio>
                                </el-radio-group>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="3" class="selectTitle">
                                <span>帳號狀態<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-radio-group v-model="state">
                                    <el-radio label="0">鎖定</el-radio>
                                    <el-radio label="1">使用中</el-radio>
                                </el-radio-group>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>角色<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-select v-model="role" placeholder="">
                                    <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                                    <el-option
                                            v-for="item in roleOptions"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value"
                                            :disabled="item.disabled">
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>信箱:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input v-model="mail" placeholder="請輸入信箱"></el-input>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="4" class="selectTitleMid">
                                <span>會員詳細資料</span>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="3" class="selectTitle">
                                <span>姓:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input v-model="name" placeholder="請輸入姓"></el-input>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>名:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input v-model="lastName" placeholder="請輸入名"></el-input>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>稱謂:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-select v-model="title" placeholder="請選擇稱謂">
                                    <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                                    <el-option label="先生" value="先生"></el-option>
                                    <el-option label="小姐" value="小姐"></el-option>
                                </el-select>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="3" class="selectTitle">
                                <span>暱稱<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input v-model="nickName" placeholder="請輸入暱稱"></el-input>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>性別:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-radio-group v-model="gender">
                                    <el-radio label="1">男</el-radio>
                                    <el-radio label="0">女</el-radio>
                                </el-radio-group>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>年齡:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input v-model="age" placeholder="請輸入年齡"></el-input>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="3" class="selectTitle">
                                <span>手機號碼<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input v-model="phoneNumber" placeholder="請輸入手機號碼"></el-input>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>生日:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-date-picker
                                        v-model="birthday"
                                        type="date"
                                        placeholder="選擇日期"
                                        @change="handleDateChange">
                                </el-date-picker>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>住址:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input v-model="address" placeholder="請輸入住址"></el-input>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="3" class="selectTitle">
                                <span>備註:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input v-model="infoRemark" placeholder="請輸入備註"></el-input>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="4" class="selectTitleMid">
                                <span>會員資產資料</span>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="3" class="selectTitle">
                                <span>資產品牌:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input v-model="brand" placeholder="請輸入資產品牌"></el-input>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>資產類型<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-select v-model="assetsModelTypeId" placeholder="">
                                    <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                                    <el-option
                                            v-for="item in assetsOptions"
                                            :key="item.assetsModelTypeId"
                                            :label="item.assetsModel"
                                            :value="item.assetsModelTypeId"
                                            :disabled="item.disabled">
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>資產顏色:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input v-model="assetsColor" placeholder="請輸入資產顏色"></el-input>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="3" class="selectTitle">
                                <span>資產識別碼<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input v-model="identifier" placeholder="請輸入資產識別碼"></el-input>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>價格區間:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input v-model="priceRange" placeholder="請輸入價格區間"></el-input>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>國內/國外:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-radio-group v-model="isImported"
                                                style="text-align: left;font-size: 16px;margin:15px 0px 15px 20px">
                                    <el-radio label="1">國內</el-radio>
                                    <el-radio label="0">國外</el-radio>
                                </el-radio-group>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="3" class="selectTitle">
                                <span>資產服務等級<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-select v-model="serviceLevelTypeId" placeholder="">
                                    <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                                    <el-option
                                            v-for="item in serviceLevelOptions"
                                            :key="item.serviceLevelTypeId"
                                            :label="item.serviceLevel"
                                            :value="item.serviceLevelTypeId"
                                            :disabled="item.disabled">
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>會員資格起算日:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-date-picker
                                        v-model="startDate"
                                        type="datetime"
                                        placeholder="選擇日期和時間">
                                </el-date-picker>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>會員資格終止日:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-date-picker
                                        v-model="endDate"
                                        type="datetime"
                                        placeholder="選擇日期和時間">
                                </el-date-picker>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="3" class="selectTitle">
                                <span>會員資格購買金額:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input
                                        v-model="purchaseAmount"
                                        placeholder="請輸入會員資格購買金額"
                                        type="number"
                                ></el-input>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>折扣率%:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input
                                        v-model="discountPercentage"
                                        placeholder="請輸入折扣率"
                                        type="number"
                                ></el-input>
                                <!--                                <el-input-number-->
                                <!--                                        v-model="discountPercentage"-->
                                <!--                                        :min="0"-->
                                <!--                                        :max="100"-->
                                <!--                                        :step="1"-->
                                <!--                                        placeholder="請輸入折扣率">-->
                                <!--                                </el-input-number>-->
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>聯絡人:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input v-model="contactName" placeholder="請輸入聯絡人"></el-input>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="3" class="selectTitle">
                                <span>聯絡人性別:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-radio-group v-model="contactGender">
                                    <el-radio label="1">男</el-radio>
                                    <el-radio label="0">女</el-radio>
                                </el-radio-group>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>聯絡人電話:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input v-model="contactPhoneNumber" placeholder="請輸入聯絡人電話"></el-input>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>備註:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input v-model="carRemark" placeholder="請輸入備註"></el-input>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="3" class="selectTitle">
                                <span>照片1<span>(僅支援jpg)</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <img v-if="photoBase64_1 != null" :src="'data:image/jpg;base64,' + photoBase64_1"
                                     alt="Image Preview 1"/>
                                <input type="file" ref="fileInpu1" @change="handleFileUpload1" accept=".jpg"/>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>照片2<span>(僅支援jpg)</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <img v-if="photoBase64_2 != null" :src="'data:image/jpg;base64,' + photoBase64_2"
                                     alt="Image Preview 1"/>
                                <input type="file" ref="fileInput2" @change="handleFileUpload2" accept=".jpg"/>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>照片3<span>(僅支援jpg)</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <img v-if="photoBase64_3 != null" :src="'data:image/jpg;base64,' + photoBase64_3"
                                     alt="Image Preview 1"/>
                                <input type="file" ref="fileInput3" @change="handleFileUpload3" accept=".jpg"/>
                            </el-col>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
            <div slot="footer" class="popBatton">
                <el-button @click="addTemplate">儲存</el-button>
                <el-button @click="closeDialog">關閉</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "addCustomerDialog",
        components: {},
        props: {
            addDialog: null,
        },
        data() {
            return {
                dialogFull: false,
                loginReturnDto: null,
                token: null,
                companyOptions: [],
                branchOptions: [],
                assetsOptions: [],
                serviceLevelOptions: [],
                serviceLevelTypeId: null,
                roleOptions:
                    [
                        {value: '99', label: '一般會員'},
                        {value: '999', label: 'VIP會員'},
                        {value: '9999', label: 'VVIP會員'},
                    ],
                userName: null,
                password: null,
                passwordVisible: false,
                errorCount: 0,
                companyId: null,
                branchId: null,
                role: '99',
                isActive: '1',
                state: '1',
                mail: null,
                name: null,
                lastName: null,
                title: null,
                nickName: null,
                gender: '1',
                age: null,
                birthday: null,
                formattedBirthday: '', // 這裡存儲格式化的日期字串
                phoneNumber: null,
                address: null,
                infoRemark: null,
                brand: null,
                assetsModel: null,
                assetsModelTypeId: null,
                assetsColor: null,
                identifier: null,
                priceRange: null,
                isImported: '1',
                serviceLevel: null,
                startDate: null,
                endDate: null,
                status: '正常',
                isUse: true,
                statusChangeDate: null,
                purchaseAmount: null,
                discountPercentage: null,
                carRemark: null,
                memberNumber: null,
                photoBase64_1: null,
                photoBase64_2: null,
                photoBase64_3: null,
                reload: false,
                contactName: null,
                contactGender: null,
                contactPhoneNumber: null,
                companyType: null,
                //是否需要公司ID
                isCompanyid: true,
                //是否需要分店ID
                isBranchid: true,
            }
        },
        computed: {
            inputType() {
                return this.passwordVisible ? 'text' : 'password';
            }
        },
        mounted() {
        },
        methods: {
            init() {
                this.isCompanyid = JSON.parse(localStorage.getItem('isCompanyid'));
                this.isBranchid = JSON.parse(localStorage.getItem('isBranchid'));
                const loginReturnDto = localStorage.getItem('loginReturnDto');
                if (loginReturnDto != null && JSON.parse(loginReturnDto) != null) {
                    this.loginReturnDto = JSON.parse(loginReturnDto);
                    this.token = this.loginReturnDto.token;
                    //系統管理員權限
                    if (this.isCompanyid) {
                        this.companyId = this.loginReturnDto.companyId;
                        this.companyType = this.loginReturnDto.companyType;
                        console.log("addCompanyDialog取得loginReturnDto.companyId=" + this.companyId);
                    }
                    //老闆權限
                    if (this.isBranchid) {
                        this.branchId = this.loginReturnDto.branchId;
                    }
                    //console.log("addCompanyDialog取得token=" + this.token);
                }
                // this.checkCompanyType();
                this.searchCompanyList();
                this.searchBranchList();
                this.searchAssetsModelTypeList();
                this.searchServiceLevelTypeList();
            },
            closeDialog() {
                console.log("關閉Pop");
                this.$emit('update:reload', this.reload);
                this.$emit('closeDialog');
                // this.checkCompanyType();
            },
            async addTemplate() {
                //新增資料
                let tmp = this;
                let url = "consumer/addCustomer";
                try {
                    var info = {
                        "name": this.name,
                        "lastName": this.lastName,
                        "title": this.title,
                        "nickName": this.nickName,
                        "gender": this.gender == null ? null : parseInt(this.gender, 10),
                        "age": this.age == null ? null : parseInt(this.age, 10),
                        "birthday": this.formattedBirthday == null || this.formattedBirthday == '' ? null : this.formattedBirthday,
                        "phoneNumber": this.phoneNumber,
                        "address": this.address,
                        "remark": this.infoRemark,
                    };
                    var assets = [];
                    var assetsDto = {
                        "brand": this.brand,
                        "assetsModelTypeId": this.assetsModelTypeId,
                        "assetsColor": this.assetsColor,
                        "identifier": this.identifier,
                        "priceRange": this.priceRange,
                        "isImported": this.isImported == null ? null : this.isImported == "0" ? false : true,
                        "serviceLevelTypeId": this.serviceLevelTypeId,
                        "memberNumber": this.memberNumber,
                        "startDate": this.startDate,
                        "endDate": this.endDate,
                        "status": this.status,
                        "isUse": this.isUse,
                        "statusChangeDate": this.statusChangeDate,
                        "purchaseAmount": this.purchaseAmount == null || this.purchaseAmount == 0 ? null : this.purchaseAmount,
                        "discountPercentage": this.discountPercentage == null || this.discountPercentage == 0 ? 0 : this.discountPercentage,
                        "photo1": null,
                        "photo2": null,
                        "photo3": null,
                        "photoBase64_1": this.photoBase64_1,
                        "photoBase64_2": this.photoBase64_2,
                        "photoBase64_3": this.photoBase64_3,
                        "photoPath1": null,
                        "photoPath2": null,
                        "photoPath3": null,
                        "remark": this.carRemark,
                        "isActive": true,
                        "contactName": this.contactName,
                        "contactGender": this.contactGender == null ? null : parseInt(this.contactGender, 10),
                        "contactPhoneNumber": this.contactPhoneNumber,
                    };
                    assets.push(assetsDto);
                    let parameter = {
                        "userName": this.userName,
                        "password": this.password,
                        "errorCount": this.errorCount,
                        "isActive": this.isActive == null ? null : this.isActive == "0" ? false : true,
                        "companyId": this.companyId,
                        "branchId": this.branchId,
                        "role": this.role == null ? null : parseInt(this.role, 10),
                        "state": this.state == null ? null : parseInt(this.state, 10),
                        "mail": this.mail,
                        "info": info,
                        "assets": assets,
                    };
                    if (!this.checkAddParameter(parameter)) {
                        console.log("新增驗證資料失敗!");
                        return;
                    }
                    tmp.reload = true;
                    tmp.loadingView();
                    let responseData = await tmp.$api.doPostByMsgToken(parameter, url, this.token);
                    if (responseData) {
                        // tmp.$api.openMsg("新增資料完成!", "success");
                        // 在延迟 2000 毫秒（2 秒）后执行代码
                        console.log("新增資料完成!");
                        setTimeout(function () {
                            tmp.closeDialog();
                            // tmp.$emit('init');
                        }, 1000);
                    } else {
                        //tmp.$api.openMsg("新增資料失敗!", "error");
                        console.log("新增資料失敗!");
                    }
                } catch (error) {
                    console.log("新增資料發生異常! error=" + error);
                } finally {
                    tmp.closeLoading();
                }
                tmp.closeLoading();
            },
            checkAddParameter(parameter) {
                var ststus = true;
                if (parameter != null && parameter != '') {
                    if (parameter.userName == null || !parameter.userName) {
                        console.log("帳號不可為空!");
                        this.$api.openMsg("帳號不可為空!", "error");
                        return false;
                    }
                    if (parameter.password == null || !parameter.password) {
                        console.log("密碼不可為空!");
                        this.$api.openMsg("密碼不可為空!", "error");
                        return false;
                    }
                    if (parameter.companyId == null || !parameter.companyId) {
                        console.log("所屬公司不可為空!");
                        this.$api.openMsg("所屬公司不可為空!", "error");
                        return false;
                    }
                    if (parameter.branchId == null || !parameter.branchId) {
                        console.log("所屬分店不可為空!");
                        this.$api.openMsg("所屬分店不可為空!", "error");
                        return false;
                    }
                    console.log("角色=" + parameter.role);
                    if (parameter.role == null) {
                        console.log("角色不可為空!");
                        this.$api.openMsg("角色不可為空!", "error");
                        return false;
                    }
                    var info = parameter.info;
                    if (info.nickName == null || !info.nickName) {
                        console.log("暱稱不可為空!");
                        this.$api.openMsg("暱稱不可為空!", "error");
                        return false;
                    }
                    if (info.phoneNumber == null || !info.phoneNumber) {
                        console.log("手機號碼不可為空!");
                        this.$api.openMsg("手機號碼不可為空!", "error");
                        return false;
                    }
                    var assets = parameter.assets[0];
                    if (assets.startDate != null && assets.startDate != '') {
                        if (assets.endDate == null || assets.endDate == '') {
                            console.log("會員資格起算日不為空時會員資格終止日不可為空!");
                            this.$api.openMsg("會員資格起算日不為空時會員資格終止日不可為空!", "error");
                            return false;
                        }
                        const start = new Date(assets.startDate);
                        const end = new Date(assets.endDate);
                        if (start > end) {
                            console.log("parameter.startDate=" + assets.startDate);
                            console.log("parameter.endDate=" + assets.endDate);
                            console.log("會員資格起算日及會員資格終止日設定時間異常!");
                            this.$api.openMsg("會員資格起算日及會員資格終止日設定時間異常!", "error");
                            return false;
                        }
                    }
                    if (assets.endDate != null && assets.endDate != '') {
                        if (assets.startDate == null || assets.startDate == '') {
                            console.log("會員資格終止日不為空時會員資格起算日不可為空!");
                            this.$api.openMsg("會員資格終止日不為空時會員資格起算日不可為空!", "error");
                            return false;
                        }
                    }
                    if (assets.assetsModelTypeId == null || !assets.assetsModelTypeId) {
                        console.log("資產類型不可為空!");
                        this.$api.openMsg("資產類型不可為空!", "error");
                        return false;
                    }
                    if (assets.identifier == null || !assets.identifier) {
                        console.log("資產識別碼不可為空!");
                        this.$api.openMsg("資產識別碼不可為空!", "error");
                        return false;
                    }
                    if (assets.serviceLevelTypeId == null || !assets.serviceLevelTypeId) {
                        console.log("資產服務等級不可為空!");
                        this.$api.openMsg("資產服務等級不可為空!", "error");
                        return false;
                    }
                } else {
                    console.log("必填資料不可為空!");
                    this.$api.openMsg("必填資料不可為空!", "error");
                    return false;
                }
                return ststus;
            },
            loadingView() {
                //開啟載入特效
                this.loadingStatus = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
            },
            closeLoading() {
                //關閉載入特效
                if (this.loadingStatus != null) {
                    this.loadingStatus.close();
                }
            },
            async searchCompanyList() {
                //查詢全部公司下拉選單資料
                let tmp = this;
                let url = "login/search/searchCompanyOptions";
                let parameter = {
                    "companyId": this.companyId,
                    "companyName": "",
                    "isActive": true
                };
                //取得列表資料
                let responseData = await tmp.$api.doPost(parameter, url);
                if (responseData != null) {
                    tmp.companyOptions = responseData;
                    console.log("取得下拉選單資料 companyOptions=" + tmp.companyOptions);
                }
            },
            async searchBranchList() {
                //查詢全部分店下拉選單資料
                let tmp = this;
                let url = "login/search/searchBranchOptions";
                let parameter = {
                    "companyId": this.companyId,
                    "branchId": this.branchId,
                    "branchName": "",
                    "isActive": true
                };
                //取得列表資料
                let responseData = await tmp.$api.doPostByToken(parameter, url, this.token);
                if (responseData != null) {
                    tmp.branchOptions = responseData;
                    console.log("取得下拉選單資料 companyOptions=" + tmp.companyOptions);
                }
            },
            validatePassword() {
                //const input = event.target.value;
                // 只允許英文大小寫字母和數字
                this.password = this.password.replace(/[^a-zA-Z0-9]/g, '');
            },
            validateUsername() {
                // 使用正則表達式檢查用戶名是否僅包含英數字大小寫以及 "-" 和 "_"
                this.userName = this.userName.replace(/[^a-zA-Z0-9-_]/g, '');
            },
            togglePasswordVisibility() {
                //檢視密碼
                this.passwordVisible = !this.passwordVisible;
            },
            handleDateChange(date) {
                if (date) {
                    // 將 Date 對象轉換為字串，格式為 YYYY-MM-DD
                    this.formattedBirthday = date.toISOString().slice(0, 10);
                } else {
                    this.formattedBirthday = '';
                }
            },
            async handleFileUpload1(event) {
                let tmp = this;
                console.log("開始載入檔案!");
                try {
                    const file = event.target.files[0]; // 獲取上傳的文件
                    // 檢查文件擴展名
                    if (file == null || !file.name.endsWith('.jpg')) {
                        tmp.$api.openMsg("僅支援上傳.jpg 文件!", "error");
                        return;
                    }
                    // 检查文件大小是否超过限制
                    if (file.size > 1024 * 1024) { // 1MB = 1024 * 1024 bytes
                        tmp.$api.openMsg("文件大小不能超过 1MB!", "error");
                        this.$refs.fileInput1.value = '';
                        return;
                    }
                    console.log("Filename=" + file.name);
                    // 使用Promise處理文件讀取
                    // this.byteArray = await this.readFileAsBytes(file);
                    this.photoBase64_1 = await this.readFileAsBase64(file);
                    console.log("匯入客戶資料! photoBase64_1=" + this.photoBase64_1);
                } catch (e) {
                    console.log("載入檔案發生異常! error=" + e);
                }
            },
            async handleFileUpload2(event) {
                let tmp = this;
                console.log("開始載入檔案!");
                try {
                    const file = event.target.files[0]; // 獲取上傳的文件
                    // 檢查文件擴展名
                    if (file == null || !file.name.endsWith('.jpg')) {
                        tmp.$api.openMsg("僅支援上傳.jpg 文件!", "error");
                        return;
                    }
                    // 检查文件大小是否超过限制
                    if (file.size > 1024 * 1024) { // 1MB = 1024 * 1024 bytes
                        tmp.$api.openMsg("文件大小不能超过 1MB!", "error");
                        this.$refs.fileInput2.value = '';
                        return;
                    }
                    console.log("Filename=" + file.name);
                    // 使用Promise處理文件讀取
                    // this.byteArray = await this.readFileAsBytes(file);
                    this.photoBase64_2 = await this.readFileAsBase64(file);
                    console.log("匯入客戶資料! photoBase64_2=" + this.photoBase64_2);
                } catch (e) {
                    console.log("載入檔案發生異常! error=" + e);
                }
            },
            async handleFileUpload3(event) {
                let tmp = this;
                console.log("開始載入檔案!");
                try {
                    const file = event.target.files[0]; // 獲取上傳的文件
                    // 檢查文件擴展名
                    if (file == null || !file.name.endsWith('.jpg')) {
                        tmp.$api.openMsg("僅支援上傳.jpg 文件!", "error");
                        return;
                    }
                    // 检查文件大小是否超过限制
                    if (file.size > 1024 * 1024) { // 1MB = 1024 * 1024 bytes
                        tmp.$api.openMsg("文件大小不能超过 1MB!", "error");
                        this.$refs.fileInput3.value = '';
                        return;
                    }
                    console.log("Filename=" + file.name);
                    // 使用Promise處理文件讀取
                    // this.byteArray = await this.readFileAsBytes(file);
                    this.photoBase64_3 = await this.readFileAsBase64(file);
                    console.log("匯入客戶資料! photoBase64_3=" + this.photoBase64_3);
                } catch (e) {
                    console.log("載入檔案發生異常! error=" + e);
                }
            },
            readFileAsBytes(file) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        resolve(new Uint8Array(reader.result)); // 将 ArrayBuffer 转换为 Uint8Array
                    };
                    reader.onerror = (error) => {
                        reject(error);
                    };
                    reader.readAsArrayBuffer(file); // 读取文件为 ArrayBuffer
                });
            },
            readFileAsBase64(file) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        resolve(reader.result.split(',')[1]); // 去掉前缀 'data:image/jpeg;base64,'
                    };
                    reader.onerror = (error) => {
                        reject(error);
                    };
                    reader.readAsDataURL(file); // 读取文件为 DataURL
                });
            },
            // 清空文件上传 input 元素的值
            clearFileInput() {
                this.$refs.fileInput1.value = '';
                this.$refs.fileInput2.value = '';
                this.$refs.fileInput3.value = '';
            },
            handleCompanyChange(value) {
                //公司下拉選單
                console.log("Selected company ID:", value);
                //系統權限，
                if (!this.isCompanyid) {
                    this.companyId = value;
                    this.branchId = null;
                    this.searchBranchList();
                }
                this.assetsModelTypeId = null;
                this.serviceLevelTypeId = null;
                this.assetsOptions = [];
                this.serviceLevelOptions = [];
                this.searchAssetsModelTypeList();
                this.searchServiceLevelTypeList();
            },
            async searchAssetsModelTypeList() {
                //依據條件查詢全部資產類型資料
                let tmp = this;
                let url = "company/search/assetsModel";
                let parameter = {
                    "companyId": this.companyId,
                    "assetsModel": this.assetsModel,
                };
                //取得列表資料
                let responseData = await tmp.$api.doPostByToken(parameter, url, this.token);
                if (responseData != null) {
                    tmp.assetsOptions = responseData;
                    console.log("取得下拉選單資料 assetsOptions=" + tmp.assetsOptions);
                }
            },
            async searchServiceLevelTypeList() {
                //依據條件查詢全部資產服務等級資料
                let tmp = this;
                let url = "company/search/serviceLevel";
                let parameter = {
                    "companyId": this.companyId,
                    "serviceLevel": this.serviceLevel,
                };
                //取得列表資料
                let responseData = await tmp.$api.doPostByToken(parameter, url, this.token);
                if (responseData != null) {
                    tmp.serviceLevelOptions = responseData;
                    console.log("取得下拉選單資料 serviceLevelOptions=" + tmp.serviceLevelOptions);
                }
            },
        }
    }
</script>

<style scoped>
    img {
        width: 50px;
        height: 50px;
        /*height: auto;*/
        margin: 10px 0px 0px 0px;
    }

    /**日期選擇器**/
    .selectInpud .el-date-editor {
        width: 100%;
    }
</style>
